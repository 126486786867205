<template>
    <div class="login_box">
        <div class="logoDiv">
            <div class="imgDiv">
                <img src="../../assets/imgs/logo1.png" alt="">
            </div>
            <div class="headerDiv">
                <p class="titleDiv">客户管理系统</p>
                <p class="tipDiv">欢迎回来，请登录到您的账户。</p>
            </div>
            <el-form>
                <el-form-item label="输入账号" class="antDiv">
                    <el-input v-model="mobile" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="输入密码(6-20位)" class="pwsDiv">
                    <el-input type="password" show-password v-model="password" clearable>
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="formBtn">
                <el-button @click="handle_loginGo">登录</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getLoginApi } from '@/api'
import { initRouter } from '@/router'
export default {
    data () {
        return {
            mobile: '',
            password: '',
        }
    },
    methods: {
        handle_loginGo () {
            getLoginApi({
                account: this.mobile,
                password: this.password
            }).then(res => {
                if (res.status.code == 200) {
                    // console.log(res)
                    sessionStorage.setItem('id', res.user_info.uid);    //存储用户id
                    sessionStorage.setItem('Token', res.user_info.access_token);  //存储token
                    sessionStorage.setItem('lower', res.user_info.has_lower);  //存储是否有下级
                    this.$store.commit('setLeftList', res.auth);   //存储用户权限
                    this.$store.commit('setUserName', res.user_info.name);  //存储用户名
                    initRouter();
                    this.$message.success('登录成功！');
                    this.$router.push({ path: '/census' });
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.login_box {
    width: 100%;
    height: 100%;
    background: url(../../assets/imgs/bglogo.png) no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .logoDiv {
        width: 452px;
        height: 410px;
        background: #ffffff;
        border-radius: 16px;
        position: relative;
        .imgDiv {
            width: 242px;
            height: 152px;
            position: absolute;
            right: -34px;
            top: -64px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .headerDiv {
            margin: 50px 40px 30px;
            text-align: left;
            .titleDiv {
                font-size: 32px;
                font-weight: 600;
                color: #333333;
                margin-bottom: 12px;
            }
            .tipDiv {
                font-size: 16px;
                font-weight: 400;
                color: #999999;
            }
        }
        .el-form {
            text-align: left;
            width: 372px;
            margin-left: 40px;
            .el-form-item {
                margin-bottom: 0 !important;
                border: 1px solid #dadada;
                height: 70px;
                .el-input {
                    // height: 60px;
                    border: none;
                }
                /deep/.el-input__inner {
                    // height: 60px;
                    border: none;
                }
                /deep/.el-form-item__label {
                    color: #999999;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    margin-left: 12px;
                    margin-top: 8px;
                }
            }
            .antDiv {
                border-radius: 6px 6px 0 0;
                border-bottom: none;
            }
            .pwsDiv {
                border-radius: 0 0 6px 6px;
            }
        }
        .formBtn {
            width: 370px;
            height: 50px;
            margin: 40px 40px 0;
            .el-button {
                width: 100%;
                height: 100%;
                background: #6a8be8;
                text-align: center;
                color: #ffffff;
                border-radius: 25px;
                border: none;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}
</style>